import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '20, 53, 105',
		'primary-dark': '15, 39, 76',
		'accent': '227, 30, 36',
		'accent-plus': '255, 255, 255',
	},
});
